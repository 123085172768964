var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import dayjs from 'dayjs';
import React from 'react';
import { KRWFomatToString, KRWFomatWithoutWon, TextComponent, AntdForm, NumberFormatInput, DrawerComponent, XScrollableTable, EntireBigTitleModal, Iframe, HookFormInputs, EntireLoader, FullFlex } from 'renderer/components';
import DatePicker from 'antd/lib/date-picker';
import { AdminQuery, NaverLambdaQuery } from 'renderer/queries';
import Modal from 'antd/lib/modal';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Statistic from 'antd/lib/statistic';
import Card from 'antd/lib/card';
import { COLORS } from 'renderer/styles';
import styled from 'styled-components';
import { DATE_FORMAT, DATE_FORMAT_INCLUDE_TIME, VBAF1231AAA } from 'renderer/constants/index';
import { Lodash, useLocalStorage, useDebounce, useWindowSize } from 'renderer/utils';
import { ManagerModel } from 'renderer/models';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import { RestClient } from 'renderer/utils';
import { Icon, Layout } from 'common-ui';
import Tabs from 'antd/lib/tabs';
import { useForm } from 'react-hook-form';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Input from 'antd/lib/input';
import modal from 'antd/lib/modal';
var today = dayjs();
export var AdminDashboard = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var handleLogin = function (ev, manager) { return __awaiter(void 0, void 0, void 0, function () {
        var result, err_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    ev.stopPropagation();
                    ev.preventDefault();
                    return [4 /*yield*/, RestClient.getInstance().post('/admin/accesstoken', {
                            loginId: manager.loginId
                        })];
                case 1:
                    result = _b.sent();
                    window.open("".concat((_a = window.location) === null || _a === void 0 ? void 0 : _a.origin, "/login?").concat(VBAF1231AAA, "=").concat(result.data.data), '_blank');
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            title: '아이디',
            dataIndex: 'id',
            key: 'id',
            render: function (value, row) {
                return "".concat(value);
            },
            sorter: function (a, b) { return a.id - b.id; }
        },
        {
            title: '상점명',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            render: function (value, row) {
                return "".concat(value, " (").concat(row.ceoName, " ").concat(row.phone, ")");
            }
        },
        {
            title: '프챠',
            dataIndex: 'franchise',
            key: 'franchise',
            render: function (value, row) {
                var _a;
                return ((_a = row === null || row === void 0 ? void 0 : row.franchise) === null || _a === void 0 ? void 0 : _a.name) || '';
            }
        },
        {
            title: '로그인',
            dataIndex: 'loginid',
            key: 'loginid',
            width: 200,
            render: function (_, row) {
                var admin = row.managers.find(function (item) { return item.role === ManagerModel.MANAGER_ROLE.STORE_ADMIN; });
                return (React.createElement(Dropdown.Button, { type: "ghost", trigger: ['hover'], onClick: function (ev) { return handleLogin(ev, admin); }, icon: React.createElement(Icon.Icon, { name: "more-horizontal", size: 24, color: COLORS.gray1, onClick: function (ev) {
                            ev.stopPropagation();
                            ev.preventDefault();
                        } }), overlay: React.createElement(Menu, null, row.managers.map(function (manager) {
                        return (React.createElement("div", { onClick: function (ev) { return handleLogin(ev, manager); } },
                            React.createElement(Menu.Item, { key: manager.loginId }, "".concat(manager.profile.name, "-").concat(manager.role === ManagerModel.MANAGER_ROLE.STORE_ADMIN ? '원장' : '직원', "-").concat(manager.loginId))));
                    })) }, admin.loginId));
            }
        },
        {
            title: '가입일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: function (value, row) {
                return dayjs(value).format(DATE_FORMAT);
            }
        },
        {
            title: '주소',
            dataIndex: 'address',
            key: 'address',
            render: function (value, row) {
                var address = row.address;
                return row.getAddressString();
            }
        },
        {
            title: '매출액',
            dataIndex: 'paidprice',
            key: 'paidprice',
            render: function (value) {
                return "".concat(KRWFomatToString({ value: Number(value) }));
            },
            sorter: function (a, b) { return a.paidprice - b.paidprice; }
        },
        {
            title: '매출건수',
            dataIndex: 'salescount',
            key: 'salescount',
            render: function (value) {
                return "".concat(KRWFomatWithoutWon({ value: Number(value) }), "\uAC74");
            },
            sorter: function (a, b) { return a.salescount - b.salescount; }
        },
        {
            title: '건당매출',
            dataIndex: 'paidPersales',
            key: 'paidPersales',
            render: function (_, row) {
                var data = Number(row.paidprice) / Number(row.salescount);
                return Number.isNaN(data) ? '0원' : KRWFomatToString({ value: Lodash.floor(data, 0) });
            },
            sorter: function (a, b) {
                var aData = !Number.isNaN(Number(a.paidprice) / Number(a.salescount))
                    ? Number(a.paidprice) / Number(a.salescount)
                    : 0;
                var bData = !Number.isNaN(Number(b.paidprice) / Number(b.salescount))
                    ? Number(b.paidprice) / Number(b.salescount)
                    : 0;
                return aData - bData;
            }
        },
        {
            title: '구독',
            dataIndex: 'susbscription',
            key: 'susbscription',
            filters: [
                {
                    text: '체험',
                    value: 'TRIAL'
                },
                {
                    text: '유료',
                    value: 'ACTIVE'
                },
                {
                    text: '무료',
                    value: 'FREE'
                }
            ],
            onFilter: function (value, record) {
                var _a;
                var status = (_a = record.subscription) === null || _a === void 0 ? void 0 : _a.status;
                if (value === 'FREE') {
                    return !status;
                }
                else {
                    return value === status;
                }
            },
            render: function (_, row) {
                var _a;
                var status = (_a = row.subscription) === null || _a === void 0 ? void 0 : _a.status;
                return status || '무료';
            }
        },
        {
            title: '네이버',
            dataIndex: 'preference.smartplace',
            key: 'preference.smartplace',
            filters: [
                {
                    text: '연동',
                    value: 'connected'
                },
                {
                    text: '활성',
                    value: 'active'
                },
                {
                    text: '미연동',
                    value: 'none'
                }
            ],
            onFilter: function (value, record) {
                var _a;
                var naverSmartPlace = (_a = record.preference) === null || _a === void 0 ? void 0 : _a.smartplace;
                if ((naverSmartPlace === null || naverSmartPlace === void 0 ? void 0 : naverSmartPlace.active) && value === 'active') {
                    return true;
                }
                else if (!!naverSmartPlace && !(naverSmartPlace === null || naverSmartPlace === void 0 ? void 0 : naverSmartPlace.active) && value === 'connected') {
                    return true;
                }
                else if (!naverSmartPlace && value === 'none') {
                    return true;
                }
            },
            render: function (_, row) {
                var _a;
                var naverSmartPlace = (_a = row.preference) === null || _a === void 0 ? void 0 : _a.smartplace;
                return !naverSmartPlace ? '' : naverSmartPlace.active ? '활성' : '연동';
            }
        },
        {
            title: '만료일',
            dataIndex: 'susbscription.expiredAt',
            key: 'susbscription.expiredAt',
            sorter: function (a, b) {
                var _a, _b;
                return ((_a = a.subscription) === null || _a === void 0 ? void 0 : _a.expiredAt) < ((_b = b.subscription) === null || _b === void 0 ? void 0 : _b.expiredAt);
            },
            render: function (_, row) {
                var _a, _b;
                var status = (_a = row.subscription) === null || _a === void 0 ? void 0 : _a.status;
                return status ? dayjs((_b = row.subscription) === null || _b === void 0 ? void 0 : _b.expiredAt).format('YYYY-MM-DD') : '-';
            }
        },
        {
            title: '포인트',
            dataIndex: ['storePoint', 'amount'],
            key: 'storePoint.amount',
            render: function (_, row) {
                return _;
            }
        },
        {
            title: '등록고객 수',
            dataIndex: 'customercount',
            key: 'customercount',
            render: function (value, row) {
                return KRWFomatWithoutWon({ value: value });
            },
            sorter: function (a, b) { return a.customercount - b.customercount; }
        },
        {
            title: '예약등록건수',
            dataIndex: 'schedulecount',
            key: 'schedulecount',
            render: function (value, row) {
                return KRWFomatWithoutWon({ value: value });
            },
            sorter: function (a, b) { return a.schedulecount - b.schedulecount; }
        }
    ];
    var _h = useLocalStorage('LOCAL_STORAGE_ADMIN_TARGET_STORE_COUNT', 100), targetCount = _h[0], setTargetCount = _h[1];
    var _j = React.useState(today), targetMonth = _j[0], setTargetMonth = _j[1];
    var _k = useWindowSize(), height = _k.height, isPC = _k.isPC;
    var metaData = AdminQuery.useJoinStatitics({
        target: targetMonth.toDate()
    });
    var now = dayjs();
    var _l = AdminQuery.useAllStoresWithStatitics({
        startedAt: now.subtract(2, 'week').toDate(),
        endedAt: now.toDate(),
        limit: 50,
        page: 1
    }), pagerResult = _l.pagerResult, getPaginateObject = _l.getPaginateObject, setNewPagerOptions = _l.setNewPagerOptions, isLoading = _l.isLoading, refetch = _l.refetch;
    var _m = React.useState(), selected = _m[0], setSelected = _m[1];
    var handleRowClick = function (record) {
        setSelected(record);
        if (!record) {
            return;
        }
    };
    var _o = React.useState(), keyword = _o[0], setKeyword = _o[1];
    var debouncedKeyword = useDebounce(keyword, 500);
    React.useEffect(function () {
        setNewPagerOptions({
            page: 1,
            limit: getPaginateObject().limit || 50,
            startedAt: now.subtract(2, 'week').toDate(),
            endedAt: now.toDate(),
            keyword: debouncedKeyword
        });
    }, [debouncedKeyword]);
    return (React.createElement(Wrapper, null,
        React.createElement(Tabs, { defaultActiveKey: "1" },
            React.createElement(Tabs.TabPane, { tab: "\uB300\uC2DC\uBCF4\uB4DC", key: "1" },
                React.createElement("div", { style: { padding: '10px' } },
                    isPC ? (React.createElement(Row, { gutter: 8 },
                        React.createElement(Col, { span: 6 },
                            React.createElement(StyledStatistic, { title: "\uCD1D\uC0C1\uC810/\uCCB4\uD5D8/\uC720\uB8CC", value: "".concat((_a = metaData.data) === null || _a === void 0 ? void 0 : _a.total.storeCount, " / ").concat((_b = metaData.data) === null || _b === void 0 ? void 0 : _b.total.trialCount, " / ").concat((_c = metaData.data) === null || _c === void 0 ? void 0 : _c.total.paidCount) })),
                        React.createElement(Col, { span: 6 },
                            React.createElement(StyledStatistic, { title: "\uC2E0\uADDC\uC0C1\uC810 / \uBAA9\uD45C\uCE58", value: "".concat(((_d = metaData.data) === null || _d === void 0 ? void 0 : _d.month.current) || 0, " / ").concat(targetCount, " (").concat(Lodash.floor((Number(((_e = metaData.data) === null || _e === void 0 ? void 0 : _e.month.current) || 0) / targetCount) * 100), "%)"), valueStyle: { color: COLORS.danger } })),
                        React.createElement(Col, { span: 6 },
                            React.createElement(StyledStatistic, { title: "\uC804\uB2EC\uB300\uBE44", value: Lodash.floor((((_f = metaData.data) === null || _f === void 0 ? void 0 : _f.month.current) / ((_g = metaData.data) === null || _g === void 0 ? void 0 : _g.month.prevMonth)) * 100, 1), suffix: "%", valueStyle: { color: COLORS.danger } })),
                        React.createElement(Col, { span: 3 },
                            React.createElement(NumberFormatInput, { value: targetCount, onChange: function (value) { return setTargetCount(value); }, placeholder: "\uBAA9\uD45C\uCC98\uC218" })),
                        React.createElement(Col, { span: 3 },
                            React.createElement(DatePicker.MonthPicker, { value: targetMonth, onChange: function (value) { return setTargetMonth(value); } })))) : (React.createElement("div", null)),
                    React.createElement(TextComponent, { children: "\uAC80\uC0C9\uC5B4(\uC0C1\uC810\uC544\uC774\uB514,\uC0AC\uC7A5\uB2D8\uC774\uB984,\uC0C1\uC810\uBA85,\uC8FC\uC18C)", marginTop: 20 }),
                    React.createElement(Row, { style: { marginBottom: '5px' } },
                        React.createElement(Col, { span: 8 },
                            React.createElement(Input, { autoFocus: true, onChange: function (ev) {
                                    setKeyword(ev.target.value);
                                } }))),
                    React.createElement(XScrollableTable, { columns: columns, rowKey: function (row) { return row.id; }, style: { height: '100%' }, scroll: { y: height - 350 }, onRow: function (record) {
                            return {
                                onClick: function (event) { return handleRowClick(record); }
                            };
                        }, dataSource: pagerResult === null || pagerResult === void 0 ? void 0 : pagerResult.items, pagination: getPaginateObject(), loading: isLoading }),
                    selected && (React.createElement(StoreDetail, { store: selected, onClose: function () { return setSelected(undefined); }, refreshStore: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var result, newStore;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0: return [4 /*yield*/, refetch()];
                                    case 1:
                                        result = _c.sent();
                                        console.log('result', result);
                                        newStore = ((_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.find(function (store) { return store.id === selected.id; })) || undefined;
                                        if (newStore) {
                                            setSelected(newStore);
                                        }
                                        else {
                                            setSelected(undefined);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); } })))),
            React.createElement(Tabs.TabPane, { tab: "\uCC44\uB110\uD1A1", key: "2" },
                React.createElement(Iframe, { src: "https://desk.channel.io/#/channels/40500/user_chats" })),
            React.createElement(Tabs.TabPane, { tab: "\uACE0\uAC1D\uD604\uD669\uC2DC\uD2B8", key: "3" },
                React.createElement(Iframe, { src: "https://docs.google.com/spreadsheets/d/1AWtYNvKiMBZQ5AepCcwsSvP13LdnBZlIgQgrKblNbXU/edit#gid=963994791" })),
            React.createElement(Tabs.TabPane, { tab: "Mixpanel", key: "4" },
                React.createElement(Iframe, { src: "https://mixpanel.com/project/2852693/view/3385479" })))));
};
var StoreDetail = function (props) {
    var _a, _b, _c;
    var record = props.store;
    var isPC = useWindowSize().isPC;
    var loginId = record.managers && record.managers[0] && record.managers[0].loginId;
    var updateStore = AdminQuery.useUpdateStore();
    var cards = [
        {
            key: 'defaultInfo',
            title: '상점 기본 정보',
            content: React.createElement(StoreBasicInformation, { store: record, refreshStore: props.refreshStore, loginId: loginId })
        },
        {
            key: 'activities',
            title: '상점 활동',
            content: React.createElement(StoreActivity, { store: record })
        },
        {
            key: 'subscriptions',
            title: '구독 현황',
            content: React.createElement(StoreSubscription, { store: record, refreshStore: props.refreshStore })
        },
        {
            key: 'smsService',
            title: '문자서비스',
            content: React.createElement(MessageService, { store: record, refreshStore: props.refreshStore })
        },
        {
            key: 'naver',
            title: '네이버예약',
            content: React.createElement(NaverReservation, { store: record, refreshStore: props.refreshStore })
        }
    ];
    return (
    /**
     * 1. 상점 등록된 카드로 결제하기
      2. 상점 포인트 충전해주기 / 상점 구독 설정 세팅
      3. 네이버 예약 정보해지 / 네이버 예약 베타 오픈 / 네이버예약 수동 가져오기 / 네이버 예약정보 변경
      4. 상점에 카카오 채널 등록하기 (상점별 카카오 템플릿 신청)
     */
    React.createElement(EntireBigTitleModal, { open: !!props.store, onCancel: props.onClose, title: "".concat((_a = props.store) === null || _a === void 0 ? void 0 : _a.name, " \uC0C1\uC138"), children: React.createElement("div", { style: { height: "100%", padding: '20px', overflowY: 'scroll' } },
            React.createElement(EntireLoader, { open: updateStore.isLoading }),
            React.createElement(TextComponent, { type: "headline3", marginBottom: 10, children: "".concat(record.id, " . ").concat(record.name, "(").concat("".concat((_b = record.address) === null || _b === void 0 ? void 0 : _b.address, " ").concat((_c = record.address) === null || _c === void 0 ? void 0 : _c.detailAddress), ")") }),
            React.createElement(Row, { gutter: 16 }, cards.map(function (card) {
                return (React.createElement(Col, { key: card.key, span: isPC ? 12 : 24, style: { marginBottom: '10px' } },
                    React.createElement(Card, { title: card.title, type: "inner" }, card.content)));
            }))) }));
};
var NaverReservation = function (props) {
    var _a, _b, _c;
    var _d = React.useState(), storeId = _d[0], setStoreId = _d[1];
    var _e = React.useState(false), edit = _e[0], setEdit = _e[1];
    var form = useForm();
    var updateStore = AdminQuery.useUpdateStore();
    React.useEffect(function () {
        handleClose();
    }, [props.store]);
    var handleClose = function () {
        var _a;
        form.reset(((_a = props.store) === null || _a === void 0 ? void 0 : _a.smsService) || {});
        setEdit(false);
    };
    var handleClear = function () { };
    var updateSMSService = AdminQuery.useUpdateSMSService();
    var smartplace = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference) === null || _b === void 0 ? void 0 : _b.smartplace;
    var bizItemsWithOptions = NaverLambdaQuery.useNaverItemsWithOptions({ storeId: (_c = props.store) === null || _c === void 0 ? void 0 : _c.id }, {
        enabled: !!props.store.isAvailableNaverSmartplace,
        retry: false
    });
    var enableDisconnect = smartplace && bizItemsWithOptions.isError;
    //   {"storeId":1566,"naverId":"plus_sharp","bookingBusinessId":"1","bookingTimeUnitCode":"RT01","businessTypeId":13,"active":true,"deleteWhenCancel":false,"shouldSendMessage":true,"blockSettings":{"types":["reservation","schedule","off"],"allowPartialDuplicate":false,"isBlockWhenCovered":false,"isActive":true,"reservation":{"afterMinute":0,"beforeMinute":0},"schedule":{"afterMinute":0,"beforeMinute":0}},"bizItems":{"188980":{"agencyKey":1,"isImp":true},"203116":{},"203202":{}},"options":{"289625":{"agencyKey":51277},"289628":{"agencyKey":51278},"289629":{"agencyKey":51279},"289630":{},"292883":{"agencyKey":51611},"292884":{"agencyKey":51610}}}
    return smartplace ? (React.createElement("div", null,
        React.createElement(EntireLoader, { open: updateSMSService.isLoading || bizItemsWithOptions.isLoading }),
        React.createElement("div", null,
            React.createElement(TextComponent, { children: "\uB124\uC774\uBC84 \uC608\uC57D \uC5F0\uB3D9 \uC0C1\uD0DC : ".concat((smartplace === null || smartplace === void 0 ? void 0 : smartplace.active) ? '연동' : '미연동') }),
            React.createElement(TextComponent, { children: "\uB124\uC774\uBC84 \uC544\uC774\uB514 : ".concat(smartplace === null || smartplace === void 0 ? void 0 : smartplace.naverId) }),
            React.createElement(TextComponent, { children: "bookingBusinessId : ".concat(smartplace === null || smartplace === void 0 ? void 0 : smartplace.bookingBusinessId) }),
            React.createElement(TextComponent, { children: "businessTypeId : ".concat(smartplace === null || smartplace === void 0 ? void 0 : smartplace.businessTypeId) })),
        React.createElement(FullFlex, { "$fullWidth": true, justify: "end" },
            React.createElement(Button, { disabled: !enableDisconnect, children: "\uC0C8\uB85C\uACE0\uCE68", style: { marginLeft: '5px', marginRight: '5px' }, onClick: function () {
                    bizItemsWithOptions === null || bizItemsWithOptions === void 0 ? void 0 : bizItemsWithOptions.refetch();
                } }),
            React.createElement(Button, { children: "\uC5F0\uB3D9\uD574\uC81C", disabled: !enableDisconnect, style: { marginLeft: '5px', marginRight: '5px' }, onClick: function () {
                    modal.confirm({
                        centered: true,
                        title: '네이버 스마트플레이스 연동해제',
                        content: (React.createElement("div", null,
                            React.createElement(TextComponent, { children: "\uC2A4\uB9C8\uD2B8\uD50C\uB808\uC774\uC2A4 \uAD00\uB9AC\uC790\uD398\uC774\uC9C0\uB97C \uD1B5\uD574 \uC5F0\uB3D9\uC774 \uC548\uB418\uC788\uC74C\uC744 \uD655\uC778\uD558\uC168\uB098\uC694?", marginBottom: 20 }))),
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            var err_2;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _c.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, updateStore.mutateAsync({
                                                id: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id,
                                                preference: __assign(__assign({}, (_b = props.store) === null || _b === void 0 ? void 0 : _b.preference), { smartplace: null })
                                            })];
                                    case 1:
                                        _c.sent();
                                        props.refreshStore();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        err_2 = _c.sent();
                                        notification.info({ message: err_2.message || '연동해제 실패' });
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); },
                        onCancel: function () { }
                    });
                } })))) : (React.createElement("div", null, "\uB124\uC774\uBC84 \uC2A4\uB9C8\uD2B8\uD50C\uB808\uC774\uC2A4 \uC5F0\uB3D9\uC774 \uC548\uB418\uC5B4\uC788\uC2B5\uB2C8\uB2E4."));
};
var MessageService = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = React.useState(), storeId = _f[0], setStoreId = _f[1];
    var _g = React.useState(false), edit = _g[0], setEdit = _g[1];
    var form = useForm();
    React.useEffect(function () {
        handleClose();
    }, [props.store]);
    var handleClose = function () {
        var _a;
        form.reset(((_a = props.store) === null || _a === void 0 ? void 0 : _a.smsService) || {});
        setEdit(false);
    };
    var handleSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var prevValues;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!((_a = values === null || values === void 0 ? void 0 : values.message) === null || _a === void 0 ? void 0 : _a.provider)) {
                        values['message'] = null;
                    }
                    if (!((_b = values === null || values === void 0 ? void 0 : values.kakao) === null || _b === void 0 ? void 0 : _b.provider)) {
                        values['kakao'] = null;
                    }
                    prevValues = (_c = props.store) === null || _c === void 0 ? void 0 : _c.smsService;
                    return [4 /*yield*/, updateSMSService.mutateAsync({
                            storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id,
                            smsService: __assign(__assign({}, prevValues), values)
                        })];
                case 1:
                    _e.sent();
                    props.refreshStore();
                    setEdit(false);
                    return [2 /*return*/];
            }
        });
    }); });
    var smsRequired = !!form.watch('message.provider');
    var kakaoRequired = !!form.watch('kakao.provider');
    var updateSMSService = AdminQuery.useUpdateSMSService();
    return (React.createElement("div", null,
        React.createElement(EntireLoader, { open: updateSMSService.isLoading }),
        React.createElement("div", null,
            React.createElement(Layout.FluidInline, { align: "center" },
                React.createElement(TextComponent, { children: "\uBB38\uC790 \uD3EC\uC778\uD2B8 : ".concat((_c = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.storePoint) === null || _b === void 0 ? void 0 : _b.amount) === null || _c === void 0 ? void 0 : _c.toLocaleString(), "\uC6D0"), marginRight: 10 }),
                React.createElement(Button, { children: "\uD3EC\uC778\uD2B8 \uBCC0\uACBD", onClick: function () { var _a; return setStoreId((_a = props.store) === null || _a === void 0 ? void 0 : _a.id); }, style: { marginLeft: '10px' } }))),
        React.createElement("div", null,
            React.createElement("form", { style: { marginTop: '10px' } },
                React.createElement(TextComponent, { children: "\uBC88\uD638\uC778\uC99D \uC5EC\uBD80 : ".concat(((_e = (_d = props.store) === null || _d === void 0 ? void 0 : _d.smsService) === null || _e === void 0 ? void 0 : _e.cert) ? '인증' : '미인증') }),
                React.createElement(HookFormInputs.Text, { label: "\uBB38\uC790 \uC81C\uACF5\uC0AC", controlProps: {
                        name: 'message.provider',
                        control: form.control,
                        rules: {
                            pattern: {
                                value: /nit/,
                                message: 'nit만 지원합니다.'
                            }
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uBC1C\uC2E0\uBC88\uD638", controlProps: {
                        name: 'message.nit.senderNumber',
                        control: form.control,
                        rules: {
                            required: smsRequired ? '알림톡 채널명을 입력해주세요.' : null
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uC54C\uB9BC\uD1A1 \uC81C\uACF5\uC0AC", controlProps: {
                        name: 'kakao.provider',
                        control: form.control,
                        rules: {
                            pattern: {
                                value: /nit/,
                                message: 'nit만 지원합니다.'
                            }
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uC54C\uB9BC\uD1A1 \uBC1C\uC2E0\uBC88\uD638", controlProps: {
                        name: 'kakao.nit.senderNumber',
                        control: form.control,
                        rules: {
                            required: kakaoRequired ? '알림톡 발신번호를 입력해주세요.' : null
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uD504\uB85C\uD544\uD0A4", controlProps: {
                        name: 'kakao.nit.profileKey',
                        control: form.control,
                        rules: {
                            required: kakaoRequired ? '프로필키를 입력해주세요.' : null
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uAC80\uC0C9\uC6A9\uC544\uC774\uB514", controlProps: {
                        name: 'kakao.nit.searchName',
                        control: form.control,
                        rules: {
                            required: kakaoRequired ? '검색용아이디를 입력해주세요.' : null,
                            validate: function (value) {
                                if (!value) {
                                    return true;
                                }
                                if (value.startsWith('@')) {
                                    return true;
                                }
                                else {
                                    return '검색용아이디는 @으로 시작해주세요.';
                                }
                            }
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(HookFormInputs.Text, { label: "\uCC44\uB110\uBA85", controlProps: {
                        name: 'kakao.nit.profileName',
                        control: form.control,
                        rules: {
                            required: kakaoRequired ? '알림톡 채널명을 입력해주세요.' : null
                        }
                    }, inputProps: {
                        disabled: !edit
                    } }),
                React.createElement(Layout.FluidInline, { justify: "end", style: { marginTop: '10px' } }, edit ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { children: "\uCDE8\uC18C", onClick: function () { return handleClose(); }, style: { marginRight: '5px' } }),
                    React.createElement(Button, { children: "\uC218\uC815\uC644\uB8CC", onClick: handleSubmit, type: "primary" }))) : (React.createElement(Button, { children: "\uC218\uC815", onClick: function () { return setEdit(true); } }))))),
        React.createElement(PointCharger, { storeId: storeId, refreshStore: function () {
                props.refreshStore();
                setStoreId(undefined);
            }, onClose: function () {
                setStoreId(undefined);
                return;
            } })));
};
var PointCharger = function (props) {
    var updateStorePoint = AdminQuery.useUpdatePoint();
    return (React.createElement(DrawerComponent, { onClose: props.onClose, title: "\uD3EC\uC778\uD2B8 \uC218\uC815", open: !!props.storeId, children: React.createElement(React.Fragment, null,
            React.createElement(EntireLoader, { open: updateStorePoint.isLoading }),
            React.createElement(AntdForm, { submitButton: "\uC800\uC7A5", formProps: {
                    // form,
                    initialValues: {
                        amount: null,
                        storeId: props.storeId,
                        description: '임의 조정'
                    },
                    onFinish: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, updateStorePoint.mutateAsync(__assign(__assign({}, value), { storeId: props.storeId }))];
                                case 1:
                                    _a.sent();
                                    notification.info({ message: '포인트 수정 완료' });
                                    props.refreshStore();
                                    return [2 /*return*/];
                            }
                        });
                    }); }
                }, onSubmit: function () { }, fields: [
                    {
                        type: 'number',
                        itemProps: {
                            name: 'amount',
                            label: '조정양',
                            rules: [{ type: 'number', required: true }]
                        },
                        inputProps: {
                            disabled: false,
                            allowNegative: true
                        }
                    },
                    {
                        type: 'text',
                        itemProps: {
                            name: 'description',
                            label: '설명',
                            rules: [{ type: 'string', required: true }]
                        },
                        inputProps: {}
                    }
                ] })) }));
};
var StoreSubscription = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var storeId = (_a = props.store) === null || _a === void 0 ? void 0 : _a.id;
    var productsQuery = AdminQuery.useOurProducts();
    var products = (_b = productsQuery.data) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.name !== 'point' && item.id !== 1; });
    var form = useForm();
    var _g = React.useState(false), edit = _g[0], setEdit = _g[1];
    var updateSubscription = AdminQuery.usePostSubscription();
    var handleSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var items, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    items = Object.keys(values)
                        .map(function (key) {
                        var _a, _b;
                        var data = values[key];
                        console.log('data', data);
                        if (!((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.productOptionId) || ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.productOptionId) === 0) {
                            return null;
                        }
                        else {
                            if (!data.expiredAt) {
                                return alert('만료일을 입력해주세요.');
                            }
                            else {
                                console.log('durl dksdha???');
                                var product = products.find(function (item) { return item.id === data.ourProductId; });
                                return __assign(__assign({}, data), { displayName: product.displayName, startedAt: dayjs().startOf('day').toDate(), expiredAt: dayjs(data === null || data === void 0 ? void 0 : data.expiredAt)
                                        .endOf('day')
                                        .toDate() });
                            }
                        }
                    })
                        .filter(Boolean);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateSubscription.mutateAsync({ storeId: storeId, items: items })];
                case 2:
                    _a.sent();
                    props.refreshStore();
                    setEdit(false);
                    notification.success({ message: '구독설정 변경 완료' });
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _a.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); });
    React.useEffect(function () {
        if (edit) {
            var values_1 = {};
            products === null || products === void 0 ? void 0 : products.map(function (item) {
                var _a, _b, _c;
                var subscription = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.subscriptions) === null || _b === void 0 ? void 0 : _b.find(function (subs) { return subs.ourProductId === item.id; });
                values_1[item.name] = {
                    name: item.name,
                    displayName: item.displayName,
                    ourProductId: item.id,
                    data: {
                        productOptionId: (_c = subscription === null || subscription === void 0 ? void 0 : subscription.data) === null || _c === void 0 ? void 0 : _c.productOptionId
                    },
                    expiredAt: subscription === null || subscription === void 0 ? void 0 : subscription.expiredAt
                };
            });
            form.reset(values_1);
        }
        else {
            form.reset(null);
        }
    }, [edit]);
    var values = form.watch();
    var noMain = ((_d = (_c = values === null || values === void 0 ? void 0 : values.small) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.productOptionId) === 0 || !((_f = (_e = values === null || values === void 0 ? void 0 : values.small) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.productOptionId);
    var _h = React.useState(), additionalDays = _h[0], setAdditionalDays = _h[1];
    return (React.createElement("div", null,
        edit && (React.createElement(Layout.Inline, { gutter: 9 },
            React.createElement(NumberFormatInput, { onChange: function (value) {
                    setAdditionalDays(Number(value));
                }, value: additionalDays }),
            React.createElement(Button, { children: "\uCD94\uAC00", type: "primary", disabled: !additionalDays, onClick: function () {
                    Object.keys(values).map(function (productName) {
                        var _a;
                        var value = values[productName];
                        if (value.expiredAt && value.ourProductId && ((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.productOptionId)) {
                            form.setValue(productName, __assign(__assign({}, value), { expiredAt: dayjs(value.expiredAt).add(additionalDays, 'day').toDate() }));
                            setAdditionalDays(null);
                        }
                        else {
                        }
                    });
                } }))), products === null || products === void 0 ? void 0 :
        products.map(function (item) {
            var _a, _b, _c, _d, _e;
            var subscription = (_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.subscriptions) === null || _b === void 0 ? void 0 : _b.find(function (subs) { return subs.ourProductId === item.id; });
            var isMain = item.type === 'plan';
            return !edit ? (React.createElement(Layout.FluidInline, { align: "start", key: item.id }, subscription ? (React.createElement(TextComponent, { children: "".concat(subscription.name, " ").concat(dayjs(subscription.expiredAt).format(DATE_FORMAT_INCLUDE_TIME)) })) : (React.createElement(TextComponent, { children: "".concat(item.displayName, " \uAD6C\uB3C5 \uC5C6\uC74C") })))) : (React.createElement("div", null,
                React.createElement(TextComponent, { children: "".concat(item.displayName), marginTop: 10, marginBottom: 5 }),
                React.createElement(Layout.FluidInline, { gutter: 9 },
                    React.createElement(HookFormInputs.SelectInput, { inputProps: {
                            placeholder: '옵션',
                            onChange: function (value) {
                                if (isMain) {
                                    products.map(function (product) {
                                        form.setValue(product.name, {
                                            name: product.name,
                                            ourProductId: product.id,
                                            data: {
                                                productOptionId: value
                                            },
                                            expiredAt: value === 1
                                                ? dayjs().add(1, 'month').toDate()
                                                : value === 2
                                                    ? dayjs().add(3, 'month').toDate()
                                                    : value === 3
                                                        ? dayjs().add(12, 'month').toDate()
                                                        : value === -1
                                                            ? dayjs().add(1, 'month').toDate()
                                                            : null
                                        });
                                    });
                                }
                                else {
                                    form.setValue(item.name, {
                                        name: item.name,
                                        ourProductId: item.id,
                                        data: {
                                            productOptionId: value
                                        },
                                        expiredAt: value === 1
                                            ? dayjs().add(1, 'month').toDate()
                                            : value === 2
                                                ? dayjs().add(3, 'month').toDate()
                                                : value === 3
                                                    ? dayjs().add(12, 'month').toDate()
                                                    : value === -1
                                                        ? dayjs().add(1, 'month').toDate()
                                                        : null
                                    });
                                }
                            },
                            options: [
                                { value: 0, label: '사용안함' },
                                { value: -1, label: '무료체험', disabled: !isMain && noMain },
                                {
                                    value: 1,
                                    label: '1개월',
                                    disabled: !isMain && (noMain || ((_c = values.small.data) === null || _c === void 0 ? void 0 : _c.productOptionId) !== 1)
                                },
                                {
                                    value: 2,
                                    label: '3개월',
                                    disabled: !isMain && (noMain || ((_d = values.small.data) === null || _d === void 0 ? void 0 : _d.productOptionId) !== 2)
                                },
                                {
                                    value: 3,
                                    label: '12개월',
                                    disabled: !isMain && (noMain || ((_e = values.small.data) === null || _e === void 0 ? void 0 : _e.productOptionId) !== 3)
                                }
                            ]
                        }, controlProps: {
                            name: "".concat(item.name, ".data.productOptionId"),
                            control: form.control
                        } }),
                    React.createElement(HookFormInputs.DatePicker
                    // label="만료일"
                    , { 
                        // label="만료일"
                        controlProps: {
                            name: "".concat(item.name, ".expiredAt"),
                            control: form.control
                        } }))));
        }),
        React.createElement(HookFormInputs.Text, { label: "\uAC31\uC2E0\uC0AC\uC720 \uC785\uB825", inputProps: {}, controlProps: {
                name: "descriptions",
                control: form.control
            } }),
        React.createElement(Layout.FluidInline, { gutter: 9, style: { marginTop: '10px' }, justify: "end" },
            React.createElement(Button, { children: edit ? '취소' : '구독수정', onClick: function () {
                    setEdit(!edit);
                } }),
            edit && React.createElement(Button, { children: '구독 설정', onClick: handleSubmit, type: "primary" }))));
};
var StoreBasicInformation = function (props) {
    var store = props.store;
    var form = useForm();
    var resetStore = AdminQuery.useResetStore();
    var handleSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            Modal.confirm({
                zIndex: 100000,
                centered: true,
                icon: null,
                title: React.createElement(TextComponent, { children: "\uD68C\uC6D0 \uC815\uBCF4 \uCD08\uAE30\uD654", type: "headline2" }),
                cancelButtonProps: {
                    ghost: true,
                    type: 'primary',
                    style: { flex: 1 }
                },
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var values, password, rest, err_4;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                values = form.getValues();
                                password = values.password, rest = __rest(values, ["password"]);
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, resetStore.mutateAsync(rest)];
                            case 2:
                                _a.sent();
                                notification.info({ message: '삭제가 완료되었습니다.' });
                                props.refreshStore();
                                return [3 /*break*/, 4];
                            case 3:
                                err_4 = _a.sent();
                                notification.error({ message: '에러가 발생했습니다.' });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () {
                    form.reset();
                },
                content: (React.createElement("div", null,
                    React.createElement(HookFormInputs.CheckboxInput, { inputProps: {
                            children: React.createElement(TextComponent, { children: "\uC0C1\uC810 \uD0C8\uD1F4" })
                        }, controlProps: {
                            name: "secession",
                            control: form.control
                        } }),
                    React.createElement(HookFormInputs.NumberInput, { label: '상점아이디', controlProps: {
                            name: 'id',
                            control: form.control,
                            rules: {
                                validate: function (value) {
                                    if (Number(value) !== props.store.id) {
                                        return '상점명아이디를 확인하세요';
                                    }
                                    return true;
                                }
                            }
                        }, inputProps: {} }),
                    React.createElement(HookFormInputs.Text, { label: '상점명', controlProps: {
                            name: 'name',
                            control: form.control,
                            rules: {
                                validate: function (value) {
                                    if (value !== props.store.name) {
                                        return '상점명을 확인하세요';
                                    }
                                    return true;
                                }
                            }
                        }, inputProps: {} }),
                    React.createElement(HookFormInputs.Text, { label: '관리자비밀번호', controlProps: {
                            name: 'password',
                            control: form.control,
                            rules: {
                                validate: function (value) {
                                    if (value !== 'gocmfflddkdlel') {
                                        return '비밀번호를 확인하세요';
                                    }
                                    return true;
                                }
                            }
                        }, inputProps: {
                            type: 'password'
                        } })))
            });
            return [2 /*return*/];
        });
    }); });
    return (React.createElement("div", null,
        React.createElement(EntireLoader, { open: resetStore.isLoading }),
        React.createElement(TextComponent, { children: "".concat(store.ceoName, "(").concat(store.phone, " - ").concat(props.loginId, ")") }),
        React.createElement(TextComponent, { children: "\uAC00\uC785\uC77C : ".concat(dayjs(store.createdAt).format(DATE_FORMAT_INCLUDE_TIME)) }),
        React.createElement(TextComponent, { children: "\uC0AC\uC5C5\uC790 \uBC88\uD638 : ".concat(store.businessLicenseNumber) }),
        React.createElement(TextComponent, { children: "\uAC00\uC785\uACBD\uB85C : ".concat(store.meta.referral) }),
        React.createElement(Layout.FluidInline, { gutter: 9, style: { marginTop: '10px' }, justify: "end" },
            React.createElement(Button, { children: "\uCD08\uAE30\uD654", type: "primary", color: "warning", onClick: handleSubmit }))));
};
var StoreActivity = function (props) {
    var store = props.store;
    var form = useForm();
    var download = AdminQuery.useDownloadStoreData();
    var handleSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            Modal.confirm({
                zIndex: 100000,
                centered: true,
                icon: null,
                title: React.createElement(TextComponent, { children: "\uC0C1\uC810 \uC815\uBCF4 \uCD94\uCD9C", type: "headline2" }),
                cancelButtonProps: {
                    ghost: true,
                    type: 'primary',
                    style: { flex: 1 }
                },
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var values, err_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                values = form.getValues();
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, download.mutateAsync(__assign(__assign({}, values), { name: store.name }))];
                            case 2:
                                _a.sent();
                                notification.info({ message: '상점정보 추출 완료' });
                                return [3 /*break*/, 4];
                            case 3:
                                err_5 = _a.sent();
                                console.log('err', err_5);
                                notification.error({ message: '에러가 발생했습니다.' });
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () {
                    form.reset();
                },
                content: (React.createElement("div", null,
                    React.createElement(HookFormInputs.NumberInput, { label: '상점아이디', controlProps: {
                            name: 'storeId',
                            control: form.control,
                            rules: {
                                validate: function (value) {
                                    if (Number(value) !== props.store.id) {
                                        return '상점명아이디를 확인하세요';
                                    }
                                    return true;
                                }
                            }
                        }, inputProps: {} }),
                    React.createElement(HookFormInputs.NumberInput, { label: '직원아이디', controlProps: {
                            name: 'managerId',
                            control: form.control
                        }, inputProps: {} })))
            });
            return [2 /*return*/];
        });
    }); });
    return (React.createElement("div", null,
        React.createElement(EntireLoader, { open: download.isLoading }),
        React.createElement(TextComponent, { children: "\uB9E4\uCD9C\uC561 : ".concat(store.paidprice) }),
        React.createElement(TextComponent, { children: "\uC608\uC57D\uAC74\uC218 : ".concat(store.schedulecount) }),
        React.createElement(TextComponent, { children: "\uB4F1\uB85D\uACE0\uAC1D \uC218 : ".concat(store.customercount) }),
        React.createElement(Layout.FluidInline, { gutter: 9, style: { marginTop: '10px' }, justify: "end" },
            React.createElement(Button, { children: "\uB370\uC774\uD130\uBD88\uB7EC\uC624\uAE30", type: "primary", color: "warning", onClick: handleSubmit }))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & .ant-tabs {\n    height: 100%;\n  }\n  & .ant-tabs-content {\n    height: 100%;\n  }\n  & .ant-tabs-nav {\n    margin: 0px;\n    padding-left: 10px;\n  }\n"], ["\n  & .ant-tabs {\n    height: 100%;\n  }\n  & .ant-tabs-content {\n    height: 100%;\n  }\n  & .ant-tabs-nav {\n    margin: 0px;\n    padding-left: 10px;\n  }\n"])));
export var FullWidthCard = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  // width: 100%;\n  height: 40px;\n\n  & .ant-card-body {\n    padding: 10px;\n  }\n"], ["\n  // width: 100%;\n  height: 40px;\n\n  & .ant-card-body {\n    padding: 10px;\n  }\n"])));
var StyledStatistic = styled(Statistic)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & .ant-statistic-content {\n    font-size: 18px;\n  }\n"], ["\n  & .ant-statistic-content {\n    font-size: 18px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
